import { Col, Image, Row, Typography } from 'antd';
import logo from '../../assets/logo/logo.png';
import dmcaImage from '../../assets/dmcaproteted.png';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { styles } from '../../styles';

export default function FooterComponent() {

  return (
    <>
    <Row gutter={24} style={{...styles.flexDisplay, marginBottom: '10px'}}>
        <Col span={16}>
            <Row gutter={24} style={styles.flexDisplay}>
                <Image width={150} src={dmcaImage} alt='dmca-logo'/>
            </Row>
            <Row gutter={24}>
                <Typography.Text style={{color: '#fff'}}>
                Disclaimer: This website does not host any of the videos mentioned. 
                Streams originating from external platforms such as YouTube are merely provided with links for convenience.
                 It is important to note that this website bears no responsibility for the legality of the content. 
                Any legal inquiries or issues should be directed to the respective media file owners or hosting platforms.
                </Typography.Text>
            </Row>
        </Col>
    </Row>
    <Row gutter={24}>
        <Col span={4}>
            <Image width={150} src={logo} alt='website-logo'/>
        </Col>
        <Col span={4}>
            <Link to={'/about-us'} >
                <Typography style={{color: '#fff'}}>About Us</Typography>
            </Link>
        </Col>
        <Col span={4}>
            <Link to={'/privacy-policy'} >
                <Typography.Text style={{color: '#fff'}}>Privacy Policy</Typography.Text>
            </Link>
        </Col>
        <Col span={4}>
            <Link to={'/contact'} >
                <Typography.Text style={{color: '#fff'}}>Contact Us</Typography.Text>
            </Link>
        </Col>
        <Col span={8}>
            <Typography.Text style={{color: '#fff'}}>Copyright © {dayjs(new Date()).format('YYYY')} Streameast All rights reserved.</Typography.Text>
        </Col>
    </Row>
    </>
  );
}
